export const researchers = [
    {
        name: 'Aparecido Nilceu Marana',
        img: 'nilceu_marana.png',
        url: 'https://lattes.cnpq.br/6027713750942689'
    },
    {
        name: 'Clayton Reginaldo Pereira',
        title: 'Post-PhD',
        img: 'clayton_pereira.png',
        url: 'https://lattes.cnpq.br/9083697774870852'
    },
    {
        name: 'João Paulo Papa',
        img: 'joao_papa.png',
        url: 'https://lattes.cnpq.br/9039182932747194'
    },
    {
        name: 'Kelton Augusto Pontara da Costa',
        img: 'kelton_costa.png',
        url: 'https://lattes.cnpq.br/3369681396058151'
    }
]

export const collaborators = [
    {
        name: 'Alexandre F.',
        img: 'alexandre_falcao.png',
        url: 'https://lattes.cnpq.br/7533729699758819'
    },
    {
        name: 'Danilo P.',
        img: 'danilo_pereira.png',
        url: 'https://lattes.cnpq.br/0122307432250869'
    },
    {
        name: 'Edmond N.',
        img: 'edmond_ng.png',
        url: 'https://www.linkedin.com/in/dredmondng/'
    },
    {
        name: 'Patricia B.',
        img: 'patricia_bellin.png',
        url: 'https://lattes.cnpq.br/8908711595824603'
    },
    {
        name: 'Rodrigo P.',
        img: 'rodrigo_pisani.png',
        url: 'https://lattes.cnpq.br/6180179840351799'
    },
    {
        name: 'Willian P.',
        img: 'willian_paraguassu.png',
        url: 'https://lattes.cnpq.br/8746409982228678'
    }
]

export const students = [
    // {
    //     // Adriana Sayuri Iwashita
    //     name: 'Adriana I.',
    //     title: 'PhD',
    //     img: 'adriana_iwashita.png',
    //     url: 'https://lattes.cnpq.br/1077685855044202'
    // },
    // {
    //     // Agnes Bressan de Almeida
    //     name: 'Agnes A.',
    //     title: 'High School',
    //     img: 'agnes_almeida.png',
    //     url: 'http://lattes.cnpq.br/1084611282072006'
    // },
    {
        // Arissa Yoshida
        name: 'Arissa Y.',
        title: 'Master',
        img: 'arissa_yoshida.png',
        url: 'https://lattes.cnpq.br/5545679207425065'
    },
    // {
    //     // Augusto Zanardi Creppe
    //     name: 'Augusto C.',
    //     title: 'High School',
    //     img: 'augusto_creppe.png',
    //     url: 'https://lattes.cnpq.br/3317303734896405'
    // },
    // {
    //     // Bruno Belluzzo
    //     name: 'Bruno B.',
    //     title: 'Master',
    //     img: 'bruno_beluzzo.png',
    //     url: 'https://lattes.cnpq.br/4335953462751748'
    // },
    {
        // Claudio Filipi Gonçalves dos Santos
        name: 'Christian B.',
        title: 'Undergraduate',
        img: 'christian_barry.png',
        url: 'http://lattes.cnpq.br/3185872760418446'
    },
    {
        // Claudio Filipi Gonçalves dos Santos
        name: 'Claudio S.',
        title: 'PhD',
        img: 'claudio_santos.png',
        url: 'https://lattes.cnpq.br/3056931143168619'
    },
    // {
    //     // Daniel Felipe Silva Santos
    //     name: 'Daniel F.',
    //     title: 'PhD',
    //     img: 'daniel_felipe.png',
    //     url: 'https://lattes.cnpq.br/2836331668288532'
    // },
    {
        // Daniel Ricardo dos Santos Jangua
        name: 'Daniel J.',
        title: 'Master',
        img: 'daniel_jangua.png',
        url: 'https://lattes.cnpq.br/1423509762623235'
    },
    {
        // Danilo Samuel Jodas
        name: 'Danilo J.',
        title: 'Post-PhD',
        img: 'danilo_jodas.png',
        url: 'https://lattes.cnpq.br/0577912365503621'
    },
    // {
    //     // Diego de Souza Oliveira
    //     name: 'Diego O.',
    //     title: 'Master',
    //     img: 'diego_oliveira.png',
    //     url: 'https://lattes.cnpq.br/3125925337546344'
    // },
    {
        // Douglas Rodrigues
        name: 'Douglas R.',
        title: 'Post-PhD',
        img: 'douglas_rodrigues.png',
        url: 'https://lattes.cnpq.br/2937000202876761'
    },
    {
        // Eduardo Alvaro Monteiro
        name: 'Eduardo M.',
        title: 'Undergraduate',
        img: 'eduardo_monteiro.png',
        url: 'https://lattes.cnpq.br/8788389948641726'
    },
    {
        // Gabriel Lino Garcia
        name: 'Gabriel L.',
        title: 'PhD',
        img: 'gabriel_lino.png',
        url: 'https://lattes.cnpq.br/1435933377049978'
    },
    {
        // Giovani Candido
        name: 'Giovani C.',
        title: 'Master',
        img: 'giovani_candido.png',
        url: 'https://lattes.cnpq.br/5980546341863758'
    },
    // {
    //     // Guilherme Brandão Martins
    //     name: 'Guilherme B.',
    //     title: 'PhD',
    //     img: 'guilherme_brandao.png',
    //     url: 'https://lattes.cnpq.br/8300636274454060'
    // },
    // {
    //     // Gustavo Henrique de Rosa
    //     name: 'Gustavo R.',
    //     title: 'PhD',
    //     img: 'gustavo_rosa.png',
    //     url: 'https://lattes.cnpq.br/0301785833241603'
    // },
    // {
    //     // Henrique Leal Tavares
    //     name: 'Henrique T.',
    //     title: 'Master',
    //     img: 'henrique_tavares.png',
    //     url: 'https://lattes.cnpq.br/1505025763440648'
    // },
    {
        // João Vitor Mariano Correia
        name: 'João C.',
        title: 'Master',
        img: 'joao_correia.png',
        url: 'https://lattes.cnpq.br/3089028857364524'
    },
    {
        // João Otávio Rodrigues Ferreira Frediani
        name: 'João F.',
        title: 'Master',
        img: 'no_photo.png',
        url: 'https://lattes.cnpq.br/2252553020515559'
    },
    {
        // João Renato Ribeiro Manesco
        name: 'João M.',
        title: 'PhD',
        img: 'joao_manesco.png',
        url: 'https://lattes.cnpq.br/2238845011342979'
    },
    {
        // Kaio Henrique Avelino Domeni de Paulo
        name: 'Kaio P.',
        title: 'Undergraduate',
        img: 'no_photo.png',
        url: 'http://lattes.cnpq.br/3994952506686107'
    },
    {
        // Leandro Aparecido Passos Junior
        name: 'Leandro P.',
        title: 'Post-PhD',
        img: 'leandro_passos.png',
        url: 'https://lattes.cnpq.br/3785255738671502'
    },
    {
        // Luis Claudio Sugi Afonso
        name: 'Luis A.',
        title: 'PhD',
        img: 'luis_afonso.png',
        url: 'https://lattes.cnpq.br/0686979081263816'
    },
    {
        // Luis Henrique Morelli
        name: 'Luis M.',
        title: 'Master',
        img: 'luis_morelli.png',
        url: 'https://lattes.cnpq.br/0608461959857316'
    },
    // {
    //     // Luis Antonio de Souza Júnior
    //     name: 'Luis S.',
    //     title: 'PhD',
    //     img: 'luis_souza.png',
    //     url: 'https://lattes.cnpq.br/0576836756526956'
    // },
    {
        // Luiz Fernando Merli de Oliveira Sementille
        name: 'Luiz S.',
        title: 'Master',
        img: 'luiz_sementille.png',
        url: 'https://lattes.cnpq.br/5631464375236201'
    },
    {
        // Marcos Cleison Silva Santana    
        name: 'Marcos C.',
        title: 'Post-PhD',
        img: 'marcos_cleison.png',
        url: 'https://lattes.cnpq.br/1817981007132675'
    },
    {
        // Maria Angélica Krüger Miranda
        name: 'Maria M.',
        title: 'Undergraduate',
        img: 'maria_miranda.png',
        url: 'http://lattes.cnpq.br/0057399689652340'
    },
    {
        // Mateus Roder
        name: 'Mateus R.',
        title: 'PhD',
        img: 'mateus_roder.png',
        url: 'https://lattes.cnpq.br/5962781692120165'
    },
    {
        // Maurício José Grapéggia Zanella
        name: 'Maurício Z.',
        title: 'Undergraduate',
        img: 'mauricio_zanella.png',
        url: 'https://lattes.cnpq.br/3466682128294764'
    },
    {
        // Milena de Toledo Araujo
        name: 'Milena A.',
        title: 'Undergraduate',
        img: 'no_photo.png',
        url: 'https://lattes.cnpq.br/9611418266113854'
    },
    // {
    //     // Murilo Varges da Silva
    //     name: 'Murilo V.',
    //     title: 'PhD',
    //     img: 'murilo_varges.png',
    //     url: 'https://lattes.cnpq.br/5501575917932892'
    // },
    {
        // Nicolas Barbosa Gomes
        name: 'Nicolas G.',
        title: 'Master',
        img: 'nicolas_gomes.png',
        url: 'https://lattes.cnpq.br/7476282222172590'
    },
    {
        // Nicole Barbosa Gomes
        name: 'Nicole G.',
        title: 'Master',
        img: 'no_photo.png',
        url: 'http://lattes.cnpq.br/0503343345988728'
    },
    {
        // Pedro Henrique Paiola
        name: 'Pedro P.',
        title: 'PhD',
        img: 'pedro_paiola.png',
        url: 'https://lattes.cnpq.br/9541625119413982'
    },
    // {
    //     // Rafael Junqueira Martarelli    
    //     name: 'Rafael M.',
    //     title: 'Master',
    //     img: 'rafael_martarelli.png',
    //     url: 'https://lattes.cnpq.br/0005057259931581'
    // },
    // {
    //     // Rafael Gonçalves Pires
    //     name: 'Rafael P.',
    //     title: 'Post-PhD',
    //     img: 'rafael_pires.png',
    //     url: 'https://lattes.cnpq.br/8410467431339373'
    // },
    {
        // Sofia Rosa
        name: 'Sofia R.',
        title: 'Undergraduate',
        img: 'sofia_rosa.png',
        url: 'http://lattes.cnpq.br/2178672020969045'
    },
    // {
    //     // Victor Hugo Braguim Canto
    //     name: 'Victor C.',
    //     title: 'Master',
    //     img: 'victor_canto.png',
    //     url: 'https://lattes.cnpq.br/7492467413721957'
    // },
    // {
    //     // Vinícius Camargo da Silva
    //     name: 'Vinícius S.',
    //     title: 'Master',
    //     img: 'vinicius_silva.png',
    //     url: 'https://lattes.cnpq.br/5637563259061367'
    // },
    {
        // Yasmin Rodrigues Sobrinho
        name: 'Yasmin S.',
        title: 'Undergraduate',
        img: 'yasmin_sobrinho.png',
        url: 'https://lattes.cnpq.br/8186258051741105'
    }
]

export const alumni = [
    {
        name: 'André F.'
    },
    {
        name: 'Bárbara B.'
    },
    {
        name: 'Bruna R.'
    },
    {
        name: 'Caio C.'
    },
    {
        name: 'Daniel O.'
    },
    {
        name: 'Dheny F.'
    },
    {
        name: 'Gustavo B.'
    },
    {
        name: 'Gustavo R.'
    },
    {
        name: 'Lucas R.'
    },
    {
        name: 'Luis A.'
    },
    {
        name: 'Luis M.'
    },
    {
        name: 'Luiz C.'
    },
    {
        name: 'Nathalia Q.'
    },
    {
        name: 'Ricardo R.'
    },
    {
        name: 'Rodrigo Y.'
    },
    {
        name: 'Silas F.'
    },
    {
        name: 'Thierry M.'
    }
]
