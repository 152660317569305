<template>
    <div id="app">
        <site-navbar @scroll="handleScroll" />
        <site-header />
        <div class="container" v-scroll-spy="{ allowNoActive: true }">
            <about-us />
            <our-members />
            <our-tools />
            <our-datasets />
            <contact-us />
        </div>
        <site-footer />
    </div>
</template>

<script>
import SiteNavbar from '@/components/SiteNavbar'
import SiteHeader from '@/components/SiteHeader'
import AboutUs from '@/components/AboutUs'
import OurMembers from '@/components/OurMembers'
import OurTools from '@/components/OurTools'
import OurDatasets from '@/components/OurDatasets'
import ContactUs from '@/components/ContactUs'
import SiteFooter from '@/components/SiteFooter'

export default {
    name: 'App',
    components: {
        SiteNavbar,
        SiteHeader,
        AboutUs,
        OurMembers,
        OurTools,
        OurDatasets,
        ContactUs,
        SiteFooter
    },
    methods: {
        handleScroll() {
            let navbar = document.querySelector('.navbar')
            if (window.scrollY > 100) {
                navbar.classList.remove('py-4');
                navbar.classList.add('scrolled', 'py-3');
            }
            else {
                navbar.classList.remove('scrolled', 'py-3');
                navbar.classList.add('py-4');
            }
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    }
}
</script>