var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"members"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"row text-center mt-4 d-flex justify-content-center"},_vm._l((_vm.researchers),function(researcher){return _c('div',{key:researcher.id,staticClass:"col mb-5"},[_c('a',{attrs:{"href":researcher.url,"target":"_blank"}},[_c('b-img',{staticClass:"mb-2",attrs:{"src":_vm.getMemberImage(researcher.img),"width":"150px","thumbnail":"","fluid":""}})],1),_c('h6',[_vm._v(" "+_vm._s(researcher.name)+" ")])])}),0),_vm._m(3),_c('div',{staticClass:"row text-center mt-4 d-flex justify-content-center"},_vm._l((_vm.collaborators),function(collaborator){return _c('div',{key:collaborator.id,staticClass:"col-6 col-sm-4 col-lg-2 mb-5"},[_c('a',{attrs:{"href":collaborator.url,"target":"_blank"}},[_c('b-img',{staticClass:"mb-2",attrs:{"src":_vm.getMemberImage(collaborator.img),"width":"100px","thumbnail":"","fluid":""}})],1),_c('h6',[_vm._v(" "+_vm._s(collaborator.name)+" ")])])}),0),_vm._m(4),_c('div',{staticClass:"row text-center mt-4 d-flex justify-content-center"},_vm._l((_vm.students),function(student){return _c('div',{key:student.id,staticClass:"col-6 col-sm-4 col-lg-2 mb-5"},[_c('a',{attrs:{"href":student.url,"target":"_blank"}},[_c('b-img',{staticClass:"mb-2",attrs:{"src":_vm.getMemberImage(student.img),"width":"100px","thumbnail":"","fluid":""}})],1),_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(student.name)+" ")]),_c('span',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(student.title)+" ")])])}),0),_vm._m(5),_c('div',{staticClass:"row text-center mt-4 d-flex justify-content-center"},_vm._l((_vm.alumni),function(pastStudent){return _c('div',{key:pastStudent.id,staticClass:"col-4 col-sm-3 col-lg-2 mb-5"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(pastStudent.name)+" ")])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col"},[_c('h2',{staticClass:"title"},[_vm._v(" Members "),_c('hr',{staticClass:"divider"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"subtitle"},[_vm._v(" This is Recogna's team, which counts with students and professors from several institutions. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center mt-5"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"title"},[_vm._v(" Main Researchers ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"title"},[_vm._v(" Collaborators ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"title"},[_vm._v(" Students ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"title"},[_vm._v(" Alumni ")])])])
}]

export { render, staticRenderFns }