export const datasets = [
    {
        type: 'Biometric Recognition',
        icon: 'fingerprint',
        color: 'rgb(74, 0, 214)',
        items: [
            {
                name: 'SignRec',
                samples: '260',
                labels: '10',
                features: '?',
                url: 'https://recogna.tech/files/datasets/signrec.tar.gz'
            }
        ]
    },
    {
        type: 'Image Classification',
        icon: 'image',
        color: 'rgb(43, 80, 233)',
        items: [
            {
                name: 'Caltech101',
                samples: '8,671',
                labels: '101',
                features: '784',
                url: 'https://recogna.tech/files/datasets/caltech101.tar.gz'
            },
            {
                name: 'CIFAR-10',
                samples: '60,000',
                labels: '10',
                features: '1,024',
                url: 'https://recogna.tech/files/datasets/cifar10.tar.gz'
            },
            {
                name: 'HandPD',
                samples: '?',
                labels: '?',
                features: '?',
                url: 'https://www2.fc.unesp.br/~papa/pub/datasets/Handpd'
            },
            {
                name: 'MNIST',
                samples: '70,000',
                labels: '10',
                features: '784',
                url: 'https://recogna.tech/files/datasets/mnist.tar.gz'
            },
            {
                name: 'MPEG-7',
                samples: '1,400',
                labels: '70',
                features: '1,024',
                url: 'https://recogna.tech/files/datasets/mpeg7.tar.gz'
            },
            {
                name: 'Semeion',
                samples: '1,593',
                labels: '10',
                features: '256',
                url: 'https://recogna.tech/files/datasets/semeion.tar.gz'
            },
            {
                name: 'Stroke',
                samples: '473',
                labels: '3',
                features: '262,144',
                url: 'https://recogna.tech/files/datasets/stroke.zip'
            },
            {
                name: 'USPS',
                samples: '9,298',
                labels: '10',
                features: '784',
                url: 'https://recogna.tech/files/datasets/usps.tar.gz'
            },
        ]
    },
    {
        type: 'Network Detection',
        icon: 'wifi',
        color: 'rgb(31, 111, 240)',
        items: [
            {
                name: 'CICIDS2017',
                samples: '2.8m',
                labels: '15',
                features: '78',
                url: 'https://recogna.tech/files/datasets/cicids2017.tar.gz'
            },
            {
                name: 'ISCXTor2016',
                samples: '67,828',
                labels: '2',
                features: '28',
                url: 'https://recogna.tech/files/datasets/tor-nontor.tar.gz'
            },
            {
                name: 'NSL-KDD',
                samples: '148,517',
                labels: '2',
                features: '40',
                url: 'https://recogna.tech/files/datasets/nsl-kdd.tar.gz'
            },
            {
                name: 'uneSPY',
                samples: '1.01m',
                labels: '2',
                features: '23',
                url: 'https://recogna.tech/files/datasets/unespy.tar.gz'
            },
            {
                name: 'Spambase',
                samples: '4,601',
                labels: '2',
                features: '48',
                url: 'https://recogna.tech/files/datasets/spambase.tar.gz'
            }
        ]
    },
    {
        type: 'Remote Sensing',
        icon: 'map-marker-alt',
        color: 'rgb(16, 148, 249)',
        items: [
            {
                name: 'Alos-Palsar',
                samples: '1',
                labels: '4',
                features: '?',
                url: 'https://recogna.tech/files/datasets/alos-palsar.tar.gz'
            },
            {
                name: 'CBERS-2B',
                samples: '1',
                labels: '6',
                features: '?',
                url: 'https://recogna.tech/files/datasets/cbers.tar.gz'
            },
            {
                name: 'Furnas',
                samples: '1',
                labels: '4',
                features: '?',
                url: 'https://recogna.tech/files/datasets/furnas.tar.gz'
            },
            {
                name: 'Geoeye',
                samples: '1',
                labels: '9',
                features: '?',
                url: 'https://recogna.tech/files/datasets/geoeye.tar.gz'
            },
            {
                name: 'Ikonos-2 MS',
                samples: '1',
                labels: '9',
                features: '?',
                url: 'https://recogna.tech/files/datasets/ikonos.tar.gz'
            },
            {
                name: 'Landsat 5 TM',
                samples: '1',
                labels: '6',
                features: '?',
                url: 'https://recogna.tech/files/datasets/landsat.tar.gz'
            },
            {
                name: 'Tietê',
                samples: '1',
                labels: '4',
                features: '?',
                url: 'https://recogna.tech/files/datasets/tiete.tar.gz'
            }
        ]
    },
    {
        type: 'Fake News Detection',
        icon: 'newspaper',
        color: 'rgb(17, 16, 249)',
        items: [
            {
                name: 'FakeRecogna',
                samples: '11,902',
                labels: '2',
                features: '?',
                url: 'https://github.com/recogna-lab/datasets/tree/0a54850ec8c17b90dc2a13c1f24d6f82bacc219b/FakeRecogna'
            }
        ]
    }
]