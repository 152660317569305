<template>
    <section id="members">
        <div class="row text-center">
            <div class="col">
                <h2 class="title">
                    Members
                    <hr class="divider">
                </h2>
            </div>
        </div>
        <div class="row text-center">
            <div class="col">
                <h6 class="subtitle">
                    This is Recogna's team, which counts with students and professors from several
                    institutions.
                </h6>
            </div>
        </div>
        <div class="row text-center mt-5">
            <div class="col">
                <h6 class="title">
                    Main Researchers
                </h6>
            </div>
        </div>
        <div class="row text-center mt-4 d-flex justify-content-center">
            <div class="col mb-5" v-for="researcher in researchers" :key=researcher.id>
                <a :href=researcher.url target="_blank">
                    <b-img class="mb-2" :src="getMemberImage(researcher.img)" width="150px" thumbnail fluid />
                </a>
                <h6>
                    {{ researcher.name }}
                </h6>
            </div>
        </div>
        <div class="row text-center">
            <div class="col">
                <h6 class="title">
                    Collaborators
                </h6>
            </div>
        </div>
        <div class="row text-center mt-4 d-flex justify-content-center">
            <div class="col-6 col-sm-4 col-lg-2 mb-5" v-for="collaborator in collaborators" :key=collaborator.id>
                <a :href=collaborator.url target="_blank">
                    <b-img class="mb-2" :src="getMemberImage(collaborator.img)" width="100px" thumbnail fluid />
                </a>
                <h6>
                    {{ collaborator.name }}
                </h6>
            </div>
        </div>
        <div class="row text-center">
            <div class="col">
                <h6 class="title">
                    Students
                </h6>
            </div>
        </div>
        <div class="row text-center mt-4 d-flex justify-content-center">
            <div class="col-6 col-sm-4 col-lg-2 mb-5" v-for="student in students" :key=student.id>
                <a :href=student.url target="_blank">
                    <b-img class="mb-2" :src="getMemberImage(student.img)" width="100px" thumbnail fluid />
                </a>
                <h6 class="mb-0">
                    {{ student.name }}
                </h6>
                <span class="small text-muted">
                    {{ student.title }}
                </span>
            </div>
        </div>
        <div class="row text-center">
            <div class="col">
                <h6 class="title">
                    Alumni
                </h6>
            </div>
        </div>
        <div class="row text-center mt-4 d-flex justify-content-center">
            <div class="col-4 col-sm-3 col-lg-2 mb-5" v-for="pastStudent in alumni" :key=pastStudent.id>
                <h6 class="mb-0">
                    {{ pastStudent.name }}
                </h6>
            </div>
        </div>
    </section>
</template>

<script>
import { researchers, collaborators, students, alumni } from '@/configs/members'

export default {
    name: 'OurMembers',
    data() {
        return {
            researchers: researchers,
            collaborators: collaborators,
            students: students,
            alumni: alumni
        }
    },
    methods: {
        getMemberImage(image) {
            return require('../assets/img/members/' + image)
        }
    }
}
</script>