<template>
    <b-navbar id="navbar" class="navbar-dark fixed-top py-4 px-5" toggleable="md">
        <div class="container-fluid d-flex align-items-center">
            <b-navbar-brand href="/#">
                <img class="mr-1" src="../assets/img/logo.png" width="45px" alt="Recogna">
                <span class="text-white">
                    Recogna
                </span>
            </b-navbar-brand>
            <b-navbar-toggle target="collapse" />
            <b-collapse id="collapse" is-nav>
                <b-navbar-nav class="ml-auto" v-scroll-spy-active v-scroll-spy-link>
                    <b-nav-item>About Us</b-nav-item>
                    <b-nav-item>Members</b-nav-item>
                    <b-nav-item>Tools</b-nav-item>
                    <b-nav-item>Datasets</b-nav-item>
                    <b-nav-item>Contact</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </div>
    </b-navbar>
</template>

<script>
export default {
    name: 'SiteNavbar'
}
</script>