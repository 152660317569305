<template>
    <section id="tools">
        <div class="row text-center">
            <div class="col">
                <h2 class="title">
                    Tools
                    <hr class="divider">
                </h2>
            </div>
        </div>
        <div class="row text-center">
            <div class="col">
                <h6 class="subtitle">
                    Glance at everything we have created so far.
                </h6>
            </div>
        </div>
        <div class="row text-center mt-5">
            <div class="col-12 col-sm-6 col-md-4 mb-4" v-for="tool in tools" :key="tool.id">
                <a :href="tool.url" target="_blank">
                    <b-card :title="tool.title" :style="{ 'background-color': tool.color }">
                        <b-card-text>
                            <img :src="getToolImage(tool.img)" width="40px">
                            <div class="mt-3">
                                {{ tool.description }}
                            </div>
                        </b-card-text>
                    </b-card>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import { tools } from '@/configs/tools'

export default {
    name: 'OurTools',
    data() {
        return {
            tools: tools
        }
    },
    methods: {
        getToolImage(image) {
            return require('../assets/img/tools/' + image)
        }
    }
}
</script>