<template>
    <header id="header" class="vh-100 mw-100">
        <div class="container h-100 d-flex flex-column align-items-center justify-content-center">
            <img class="mb-1" src="../assets/img/full-logo.png" width="200px">
            <h5 class="text-white">
                Biometric & Pattern Recognition Research Group
            </h5>
            <div class="mt-5" v-scroll-spy-link>
                <a>
                    <font-awesome-icon class="text-white" :icon="['fas', 'circle-chevron-down']" size="2x" pulse />
                </a>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'SiteHeader'
}
</script>

