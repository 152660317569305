<template>
    <footer id="footer" class="py-4 mt-2">
        <div class="container d-flex justify-content-between align-items-center flex-wrap">
            <div>
                <span>&copy; {{ currentYear }} Recogna Laboratory</span>
            </div>
            <div>
                <span>
                    Maintained with
                    <font-awesome-icon class="text-danger" :icon="['fas', 'heart']" />
                    by
                    <a href="https://github.com/giovcandido" target="_blank">Giovani Candido</a> and
                    <a href="https://github.com/Xilsu" target="_blank">Luis Morelli</a>
                </span>
            </div>
            <div>
                <span>
                    Created by
                    <a href="https://github.com/gugarosa/recogna_web/tree/master" target="_blank">Gustavo de Rosa</a>
                </span>
            </div>
            <div>
                <span>
                    <font-awesome-icon class="mr-1" :icon="['fas', 'inbox']" />
                    <a href="mailto:recogna@fc.unesp.br">recogna@fc.unesp.br</a>
                </span>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'SiteFooter',
    data() {
        return {
            currentYear: ''
        }
    },
    mounted() {
        this.currentYear = new Date().getFullYear()
    }
}
</script>