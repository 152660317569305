<template>
    <section id="about">
        <div class="row text-center">
            <div class="col">
                <h2 class="title">
                    About Us
                    <hr class="divider">
                </h2>
            </div>
        </div>
        <div class="row text-center">
            <div class="col">
                <h6 class="subtitle">
                    This is just a sneak peek of everything that we can offer.
                </h6>
            </div>
        </div>
        <div class="row text-center mt-5">
            <div class="col">
                <b-card>
                    <b-card-text>
                        The <b>Biometric & Pattern Recognition Research Group</b> (Recogna),
                        formerly known as the High-Performance Computing Laboratory, was established with the primary
                        goal of advancing research in <b>computer vision, machine learning, and biometrics</b>.
                        Our vision extends beyond pursuing state-of-the-art research; we also aim to cultivate a
                        <b>culture of collaborative research and teaching</b>, fostering a passion for
                        knowledge dissemination. If you relate with these principles and aspire to contribute to
                        cutting-edge research in a collaborative environment, Recogna is the right place for you.
                    </b-card-text>
                </b-card>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutUs'
}
</script>