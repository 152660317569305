<template>
    <section id="datasets">
        <div class="row text-center">
            <div class="col">
                <h2 class="title">
                    Datasets
                    <hr class="divider">
                </h2>
            </div>
        </div>
        <div class="row text-center">
            <div class="col">
                <h6 class="subtitle">
                    Feel free to download our own datasets in order to work with our libraries. Also, it is possible to
                    download other datasets already modelled to our file format. Please, remember to cite the
                    appropriate references when using a specific dataset.
                </h6>
            </div>
        </div>
        <div class="row text-center mt-5">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 mb-4" v-for="dataset in datasets" :key="dataset.id">
                <b-card v-b-modal="dataset.type" :style="{ 'background-color': dataset.color }">
                    <b-card-text>
                        <font-awesome-icon :icon="['fas', dataset.icon]" />
                        {{ dataset.type }}
                    </b-card-text>
                </b-card>
                <b-modal :id="dataset.type" centered>
                    <template slot="modal-title">
                        <span :style="{ 'color': dataset.color }">
                            <font-awesome-icon :icon="['fas', dataset.icon]" />
                            {{ dataset.type }}
                        </span>
                    </template>
                    <template slot="default">
                        <div class="dataset-item text-left" v-for="item in dataset.items" :key="item.id">
                            <a :href="item.url" target="_blank">
                                <b>{{ item.name }}</b>: {{ item.samples }} samples, {{ item.labels }} labels, {{
                                    item.features }} features
                            </a>
                            <hr>
                        </div>
                    </template>
                    <template slot="modal-footer">
                        <div></div>
                    </template>
                </b-modal>
            </div>
        </div>
    </section>
</template>

<script>
import { datasets } from '@/configs/datasets'

export default {
    name: 'OurDatasets',
    data() {
        return {
            datasets: datasets
        }
    }
}
</script>